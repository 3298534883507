
import React from "react";
import { useRoutes, Navigate} from "react-router-dom";
import Dashboard from "./Dashboard";
import LoginPage from "./Components/LoginPage";
import Layout from "./Components/Shared/Layout";
import CRM from "./Components/CRM";
import ZohoForm from "./Components/ZohoForm";
import Registration from "./RegistrationForm/Registration";
import EditUser from "./RegistrationForm/EditUser";
import ThankYouPage from "./Components/ThankYouPage";
import GroupCourseDetails from "./RegistrationForm/GroupCourseDetails";
import CourseLessonDetails from "./RegistrationForm/CourseLessonDetails";
import CHRMP from "./Components/CHRMP";
import Customer from "./RegistrationForm/Customer";


const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem('userToken')

  return isAuthenticated ? (element) : (<Navigate to="/" />);
};

const Router = () => {
  const routes = useRoutes([
    { path: '/', element: <LoginPage /> },
    { path: '/form', element: <Registration /> },
    { path: '/certification/:id', element: (<PrivateRoute path="/certification/:id" element={<EditUser />}/>) },
    { path: '/customer/:id', element: (<PrivateRoute path="/customer/:id" element={<Customer />}/>) },
    { path: '/group/:groupId', element: (<PrivateRoute path="/group/:groupId" element={<GroupCourseDetails />}/>) },
    { path: '/course/:courseId', element: (<PrivateRoute path="/course/:courseId" element={<CourseLessonDetails />}/>) },
    { path: '/thankyouPage', element: <ThankYouPage /> },
    { path: '/dashboard',element: (<PrivateRoute path="/dashboard" element={<Layout><Dashboard /></Layout>}/>),},
    { path: '/chrmp',element: (<PrivateRoute path="/chrmp" element={<Layout><CHRMP /></Layout>}/>),},
    { path: '/crm',element: (<PrivateRoute path="/crm" element={<Layout><CRM /></Layout>}/>),},
    { path: '/zoho-form',element: (<PrivateRoute path="/zoho-form" element={<Layout><ZohoForm /></Layout>}/>),},
  ]);

  return routes;
};

export default Router;
