import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const fetchCertifications = createAsyncThunk(
  'certifications/fetchCertifications',
  async (page) => {
    const response = await axios.get(`${baseUrl}/certifications/?page=${page}`);
    return response.data.results;
  }
);

export const deleteCertification = createAsyncThunk(
  'certifications/deleteCertification',
  async (id, { dispatch }) => {
    await axios.delete(`${baseUrl}/certifications/${id}`);
    dispatch(removeCertification(id));  // Dispatch the slice action to update the state
  }
);

const certificationsSlice = createSlice({
  name: 'certifications',
  initialState: {
    data: [],
    currentPage: 1,
    totalNoOfPageCount: 1,
    totalNoOfData: 1,
    isLoading: false,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    removeCertification: (state, action) => {
      state.data = state.data.filter(
        (item) => item.certification_id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCertifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchCertifications.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export const { setCurrentPage, removeCertification } = certificationsSlice.actions;

export default certificationsSlice.reducer;
