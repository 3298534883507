
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiOutlineArchive, HiPencilAlt } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCertifications, setCurrentPage, deleteCertification } from '../Redux/CertificationsSlice';

const Tabulator = () => {
  const dispatch = useDispatch();
  const { data, currentPage, isLoading } = useSelector((state) => state.certifications);
  const searchResults = useSelector((state) => state.search.results);
  const isDataFound = useSelector((state) => state.search.isDataFound);
  const displayData = searchResults.length > 0 ? searchResults : data;

  useEffect(() => {
    if (!searchResults.length) {
      dispatch(fetchCertifications(currentPage));
    }
  }, [searchResults, currentPage, dispatch]);

  const handleDeleteClick = (id) => {
    const result = window.confirm('Are you sure you want to proceed?');
    if (result) {
      dispatch(deleteCertification(id));
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    dispatch(setCurrentPage(currentPage + 1));
  };

  const changeColor = {
    certification :{
      bgColor: '#fffbe7',
      borderColor: '#b39700',
      fontColor:'#b39700'
    },
    masterclass :{
      bgColor: '#b8dcff',
      borderColor: '#0053a4',
      fontColor:'#0053a4'
    },
    form :{
      bgColor: '#e7ffe7',
      borderColor: '#00b400',
      fontColor:'#00b400'
    },
    CRM :{
      bgColor: '#ffe8ff',
      borderColor: '#9a009a',
      fontColor:'#9a009a'
    },
  }

  return (
    <div className="min-h-screen mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{searchResults.length > 0 ? 'Search Result' : 'Certification'}</h1>
      <div className="overflow-x-auto max-w-full">
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left border-b">User Name</th>
              <th className="px-4 py-2 text-left border-b">Email</th>
              <th className="px-4 py-2 text-left border-b">{searchResults.length > 0 ? 'CreatedAt' : 'Course'}</th>
              <th className="px-4 py-2 text-center border-b">{searchResults.length > 0 ? 'Type' : 'Status'}</th>
              <th className="px-4 py-2 text-center border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
          {isDataFound ? (
              displayData.length > 0 ? (
                displayData.map((item, index) => {
                  const colorStyles = changeColor[item.type] || {};
                  return (
                    <tr key={searchResults.length > 0 ? item.id : item.certification_id} className={index % 2 === 0 ? 'bg-[#F1F5F9] border-b' : 'border-b'}>
                      <td className="px-4 py-2 text-left capitalize">{item.name}</td>
                      <td className="px-4 py-2 text-left">{item.email}</td>
                      <td className="px-4 py-2 text-left">{searchResults.length > 0 ? (item.createdDate) : (item.certificationProgram)}</td>
                      <td className="px-4 py-2 text-center">
                        {searchResults.length > 0 ? (
                          <button
                            className="px-6 py-[2px] rounded-3xl font-medium text-sm"
                            style={{
                              backgroundColor: colorStyles.bgColor,
                              borderColor: colorStyles.borderColor,
                              color: colorStyles.fontColor,
                              border: `2px solid ${colorStyles.borderColor}`
                            }}
                          >
                            {item.type}
                          </button>
                        ) : (
                          item.status
                        )}
                      </td>
                      <td className="px-4 py-2 text-center flex items-center justify-center">
                        <Link to={`/${searchResults.length > 0 && item.type != "certification" ? "customer": "certification"}/${searchResults.length > 0 ? item.id : item.certification_id}`}>
                          <button className="text-black py-2 px-3 rounded flex items-center justify-center">
                            <HiPencilAlt className="text-lg text-blue-700" />
                            <span>Edit</span>
                          </button>
                        </Link>
                        <button
                          className="text-black py-2 px-3 rounded flex items-center justify-evenly"
                          onClick={() => handleDeleteClick(searchResults.length > 0 ? item.id : item.certification_id)}
                        >
                          <HiOutlineArchive className="text-lg text-red-500 hover:text-white hover:bg-red-500 hover:shadow-red-500 hover:shadow-lg hover:rounded-full" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                    No data found.
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                  No search results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-end mt-5 gap-5">
          <button className="py-2 px-5 bg-slate-200 rounded-md" onClick={handlePreviousPage}>Previous</button>
          <button className="py-2 px-5 bg-slate-200 rounded-md" onClick={handleNextPage}>Next</button>
        </div>
      </div>
      {isLoading && (
        <div className="flex items-center justify-center mt-4">
          <p className="text-gray-500">Loading...</p>
        </div>
      )}
    </div>
  );
};

export default Tabulator;
