import { configureStore } from '@reduxjs/toolkit'
import userReducer from './UserSlice'
import searchReducer from './SearchSlice'
import certificationsReducer from './CertificationsSlice';
import customersReducer from './CustomersSlice';


export const store = configureStore({
  reducer: {
    user : userReducer,
    search : searchReducer,
    certifications: certificationsReducer,
    customers: customersReducer,

  },
  
});