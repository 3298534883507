import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const GroupCourseDetails = () => {
    const { groupId } = useParams();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const userToken = JSON.parse(localStorage.getItem('userDatas'));
    const baseUrl = process.env.REACT_APP_BASE_API_URL
    const navigate = useNavigate()
  
    useEffect(() => {
      const fetchCourses = async () => {
        try {
          const coursesRes = await axios.get(`${baseUrl}/courses/`, {
            params: {
              group_id: groupId,
              token: userToken,
            }
          });
  
          if (coursesRes.status === 200) {
            console.log(coursesRes.data.results);
            const courses = coursesRes.data.results
            const fetchedCourseNames = courses.map(course => ({
              id : course.id,
              name: course.title.rendered, 
            }));
            setCourses(fetchedCourseNames);
          } else {
            console.error("Courses request failed");
          }
        } catch (error) {
          console.error("Request error:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchCourses();
    }, [groupId, userToken]);

    const handleCourseClick = (courseId) => {
      navigate(`/course/${courseId}`);
    };
  
    return (
      <div>
        <h1 className="text-2xl font-bold mb-4">Courses in Group {groupId}</h1>
        {loading ? (
          <div className="text-gray-600">Loading ...</div>
        ) : (
          <div>
            {courses.map((course, index) => (
              <div key={index} className="text-white rounded-md mb-4 border-solid border-2 border-gray-200 shadow-cyan-700 shadow-sm cursor-pointer">
                <div className="flex justify-between items-center text-[#2960A1] border-b border-gray-200 w-full p-4">
                  <span className="text-[#2960A1] font-semibold text-lg" onClick={() => handleCourseClick(course.id)}>{course.name}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default GroupCourseDetails;