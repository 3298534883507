import React, { useState } from 'react';
import { HiOutlineMenu, HiOutlineSearch } from 'react-icons/hi';
import axios from 'axios';
import chrmpLogo from '../../CHRMP Logo - Tagline.svg';
import { useDispatch } from 'react-redux';
import { addSearchResults, clearSearchResults, changeIsDataFound } from '../../Redux/SearchSlice';

const Header = ({ isSidebarOpen, setSidebarOpen }) => {
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const [searchValue, setSearchValue] = useState('');
  const searchDispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_API_URL

  const handleSearch = async () => {
    const trimmedSearchValue = searchValue.trim().toLowerCase();

    if (trimmedSearchValue !== '') {
      try {
        const response = await axios.get(`${baseUrl}/advanced-search/?search=${trimmedSearchValue}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
          }
        });
  
        console.log(response.data.results);
        const searchData = await response.data.results
        searchDispatch(addSearchResults(searchData));
      } catch (error) {
        console.log(error.response);
        if (error.response && error.response.status === 404) {
          searchDispatch(changeIsDataFound());
        } else {
          console.error('Error fetching search record:', error);
        }
      }
    } else {
      searchDispatch(clearSearchResults());
    }
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    setSearchValue(event.target.value);
    if (event.target.value.trim() === '') {
      searchDispatch(clearSearchResults());
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="bg-[#F1F5F9] p-6 shadow-md rounded-t-xl border-b ml-">
      <div className="text-2xl font-bold flex justify-between items-center gap-10">
        <div className="sm:hidden md:flex">
          <img src={chrmpLogo} className="w-24" alt="logo" />
        </div>
        <div className={`hidden mobile:${!isSidebarOpen ? 'flex' : 'hidden'}`}>
          <HiOutlineMenu className="" onClick={toggleSidebar} />
        </div>
        <div className="flex bg-[#DEE5ED] items-center w-auto gap-2 rounded-3xl justify-center pr-4">
          <input
            type="text"
            className="bg-[#DEE5ED] outline-none rounded-3xl pl-4 p-2 font-normal text-sm "
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
          />
          <HiOutlineSearch className="text-[#475569] text-sm cursor-pointer" onClick={handleSearch} />
        </div>
      </div>
    </div>
  );
};

export default Header;
