import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const CourseLessonDetails = () => {
    const { courseId } = useParams();
    const [lessons, setLessons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1)
    const userToken = JSON.parse(localStorage.getItem('userDatas'));
    const baseUrl = process.env.REACT_APP_BASE_API_URL
  
    useEffect(() => {
      const fetchLessons = async () => {
        try {
          const lessonRes = await axios.get(`${baseUrl}/lessons/`, {
            params: {
              course_id: courseId,
              token: userToken,
              page_no: page,
            }
          });
  
          if (lessonRes.status === 200) {
            console.log(lessonRes.data.results);
            const lessons = lessonRes.data.results
            const fetchedLessonNames = lessons.map(lesson => ({
              id : lesson.id,
              name: lesson.title.rendered, 
            }));
            setLessons(fetchedLessonNames);
          } else {
            console.error("Courses request failed");
          }
        } catch (error) {
          console.error("Request error:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchLessons();
    }, [courseId, userToken]);
  
    return (
      <div>
        <h1 className="text-2xl font-bold mb-4">Lessons in Course  {courseId}</h1>
        {loading ? (
          <div className="text-gray-600">Loading ...</div>
        ) : (
          <div>
            {lessons.map((lesson, index) => (
              <div key={index} className="text-white rounded-md mb-4 border-solid border-2 border-gray-200 shadow-cyan-700 shadow-sm">
                <div className="flex justify-between items-center text-[#2960A1] border-b border-gray-200 w-full p-4">
                  <span className="text-[#2960A1] font-semibold text-lg">{lesson.name}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default CourseLessonDetails;