

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const GroupDetails = ({ userId }) => {
  const [groupNames, setGroupNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = JSON.parse(localStorage.getItem('userDatas'));
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_API_URL

  useEffect(() => {
    if (userId) {
      const fetchGroups = async () => {
        try {
          const groupRes = await axios.get(`${baseUrl}/groups/`, {
            params: {
              user_id: userId,
              token: userToken,
            }
          });

          if (groupRes.status === 200) {
            const groups = groupRes.data.results;
            console.log(groups);
            const fetchedGroupNames = groups.map(group => ({
              id : group.id,
              name: group.title.rendered, 
            }));
            setGroupNames(fetchedGroupNames);
            
          } else {
            console.error("Course request failed");
          }
        } catch (error) {
          console.error("Request error:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchGroups();
    }
  }, [userId, userToken]);

  const handleGroupClick = (groupId) => {
    navigate(`/group/${groupId}`);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Group Details</h1>
      <h2 className="text-xl font-semibold mb-2">{userId}</h2>
      {loading ? (
        <div className="text-gray-600">Loading ...</div>
      ) : (
        <div>
          {groupNames.map((group, index) => (
            <div key={group.id} className="text-white rounded-md mb-4 border-solid border-2 border-gray-200 shadow-cyan-700 shadow-sm cursor-pointer">
              <div className="flex justify-between items-center text-[#2960A1] border-b border-gray-200 w-full p-4">
                <button className="text-[#2960A1] font-semibold text-lg" onClick={() => handleGroupClick(group.id)}>{group.name}</button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupDetails;
