import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    results: [],
    isDataFound: true, // Initialize as true
  },
  reducers: {
    addSearchResults: (state, action) => {
      state.results = action.payload;
      state.isDataFound = action.payload.length > 0; // Data found if array is not empty
    },
    clearSearchResults: (state) => {
      state.results = [];
      state.isDataFound = true; // Clear results, set to true (no search performed)
    },
    changeIsDataFound: (state) => {
      state.isDataFound = false; // No data found, set to false
    },
  },
});

export const { addSearchResults, clearSearchResults, changeIsDataFound } = searchSlice.actions;
export default searchSlice.reducer;
