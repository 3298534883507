import { useParams } from "react-router-dom";
import { HiPlus, HiX } from "react-icons/hi";
import { useEffect, useState } from "react";
import axios from "axios";


const Customer = () => {
  const { id } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_API_URL
  const [individualCustomerData, setIndividualCustomerData] = useState({});
  const [loading, setLoading] = useState(false);

useEffect(() => {
  const handleSingleData = async () => {
    try {
      // Make a GET request to fetch the individual record
      const response = await axios.get(`${baseUrl}/customers/${id}/`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
        }
      });

      console.log(response.data);
      setIndividualCustomerData(response.data)
    } catch (error) {
      console.error('Error fetching individual record:', error);
    }
  }


  handleSingleData()
}, [id]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.put(`${baseUrl}/customers/${id}/`,{...individualCustomerData},{
        headers: {
            // 'Authorization': '',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin':'*'
        },
      });
      console.log(response.data); // Assuming your API returns updated data
      alert('User data updated successfully!');
      setLoading(false);
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user data. Please try again later.');
      setLoading(false);
    }
  };

  return (
    <div className="h-screen w-screen flex items-center shadow-md overflow-hidden">
      <div className="w-full h-full overflow-y-scroll bg-gray-100 rounded-e-md shadow-md">
      <div className="flex flex-wrap gap-4 m-10 mb-0">
      <div className="w-full flex h-auto items-center justify-between">
  <input
    type="text"
    name="name"
    className="w-auto mb-2 border-b-2 text-5xl font-semibold bg-transparent outline-none capitalize"
    placeholder="Name"
    value={individualCustomerData.name === null? 'Undefined':individualCustomerData.name}
    onChange={(e) => setIndividualCustomerData({ ...individualCustomerData, name: e.target.value.toLocaleLowerCase() })}
  />
</div>

              {/* <label className="mb-2">Date of Birth</label> */}
              <div className="w-1/3 ">
              <input type="text" name="email" className="w-full mb-2 border-b-2 outline-none bg-transparent" placeholder="Email" value={individualCustomerData.email === null? 'Undefined':individualCustomerData.email} onChange={(e) => setIndividualCustomerData({ ...individualCustomerData, email: e.target.value })}/>
              </div>
              
              {/* <label className="mb-2">Age</label> */}
              <div className="w-1/3">
              <input type="text" name="gender" className="w-full mb-2 border-b-2 outline-none bg-transparent" placeholder="Gender" value={individualCustomerData.gender === null? 'Undefined':individualCustomerData.gender}onChange={(e) => setIndividualCustomerData({ ...individualCustomerData, gender: e.target.value })}/>
            </div>
            {/* <div>
              <button className="bg-[#2960A1] px-2 py-1 text-white hover:bg-[#8DC162] rounded-md" onClick={handleAddEmail}>Send Email</button>
            </div> */}
          </div>
          <div>
            <div className="p-4 rounded-lg ">
              <div>
              <h2 className="text-2xl font-semibold m-8">Personal Details</h2>
                <div className="flex flex-wrap gap-4 m-8">
              <div className="w-1/3">
              <label className="mb-2 font-medium">Address</label>
              <input type="text" name="streetAddress" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.streetAddress === null? 'Undefined':individualCustomerData.streetAddress} onChange={(e) => setIndividualCustomerData({...individualCustomerData, streetAddress: e.target.value })} />
              <label className="mb-2 font-medium">Address 2</label>
              <input type="text" name="addressLine2" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.addressLine2 === null? 'Undefined':individualCustomerData.addressLine2} onChange={(e) => setIndividualCustomerData({...individualCustomerData, addressLine2: e.target.value })} />
              <label className="mb-2 font-medium">Phone</label>
              <input type="text" name="phone" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.phone === null? 'Undefined':individualCustomerData.phone}  onChange={(e) => setIndividualCustomerData({...individualCustomerData, phone: e.target.value })} />
              <label className="mb-2 font-medium">City</label>
              <input type="text" name="city" className="w-full mb-2 mt-2 border-b-2 outline-none bg-transparent"  value={individualCustomerData.city === null? 'Undefined':individualCustomerData.city}  onChange={(e) => setIndividualCustomerData({...individualCustomerData, city: e.target.value })} />
            </div>
            <div className="w-1/3">
              <label className="mb-2 font-medium">State</label>
              <input type="text" name="state" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.state === null? 'Undefined':individualCustomerData.state}  onChange={(e) => setIndividualCustomerData({...individualCustomerData, state: e.target.value })}/>
              <label className="mb-2 font-medium">Zipcode</label>
              <input type="text" name="zipcode" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.zipcode === null? 'Undefined':individualCustomerData.zipcode}  onChange={(e) => setIndividualCustomerData({...individualCustomerData, zipcode: e.target.value })} />
              <label className="mb-2 font-medium">Alternative Phone</label>
              <input type="text" name="alternativePhone" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.alternativePhone === null? 'Undefined':individualCustomerData.alternativePhone}  onChange={(e) => setIndividualCustomerData({...individualCustomerData, alternativePhone: e.target.value })} />
              <label className="mb-2 font-medium">Country</label>
              <input type="text" name="country" className="w-full mb-2 mt-2 border-b-2 outline-none bg-transparent"value ={individualCustomerData.country === null? 'Undefined':individualCustomerData.country} onChange={(e) => setIndividualCustomerData({...individualCustomerData, country: e.target.value })} />
            </div>
          </div>
              </div>
              
              <div>
                <h2 className="text-2xl font-semibold mt-4 m-8">Product Details</h2>
                  <div className="flex flex-wrap gap-4 m-8">
                    <div className="w-1/3">
                      <label className="mb-2 font-medium">Product</label>
                      <input type="text" name="product" className="w-full mb-2 mt-2 border-b-2 outline-none bg-transparent"  value={individualCustomerData.product === null? 'Undefined':individualCustomerData.product} onChange={(e) => setIndividualCustomerData({...individualCustomerData, product: e.target.value })} />
                      </div>
                  </div>
              </div>
                
              <div>
              <h2 className="text-2xl font-semibold m-8">Education Qualification & Experience</h2>
            <div className="flex flex-wrap gap-4 m-8">
            <div className="w-1/3">
                <label className="mb-2 font-medium">Organization</label>
                <input type="text" name="organization" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value ={individualCustomerData.organization === null? 'Undefined':individualCustomerData.organization} onChange={(e) => setIndividualCustomerData({...individualCustomerData, organization: e.target.value })} />
              <label className="mb-2 font-medium">Total Year of Experience</label>
              <input type="text" name="totalExperience" className="w-full mb-4 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.totalExperience === null? 'Undefined':individualCustomerData.totalExperience} onChange={(e) => setIndividualCustomerData({...individualCustomerData, totalExperience: e.target.value })}/>
            </div>
            <div className="w-1/3">
              <label className="mb-2 font-medium">Designation</label>
              <input type="text" name="designation" className="w-full mb-2 mt-2 border-b-2 outline-none bg-transparent" value={individualCustomerData.designation === null? 'Undefined':individualCustomerData.designation} onChange={(e) => setIndividualCustomerData({...individualCustomerData, designation: e.target.value })} />
            </div>
        </div>
            <button type="submit" className="bg-[#2960a1] m-6 hover:bg-[#8DC162] text-white py-2 px-4 rounded-md focus:outline-none transition duration-300 ease-in-out font-medium" onClick={handleSubmit} disabled={loading}>{loading ? "Updating..." : "Update"}</button>
            {/* <button type="submit" className="bg-[#2960a1] m-6 hover:bg-[#8DC162] text-white py-2 px-4 rounded-md focus:outline-none transition duration-300 ease-in-out font-medium" onClick={handleMigrateCollection}>Upload</button> */}
            {/* <GroupDetails userId={userId} /> */}
          </div>
        </div>
       </div>       
    </div>
  </div>
  );
}

export default Customer;
